import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { axiosApi } from '@/api'
import { useAlertStore } from '@/stores/alert'
import fieldsEditingRules from '@/stores/user/fields_editing_rules.json'
import actionRights from '@/stores/user/action_rights.json'
import { uniq } from 'lodash'
import { CUSTOMER_USER_RIGHT } from '@/constants'

export const useUserStore = defineStore('user', () => {
  const alert = useAlertStore()

  const isLoading = ref(false)
  const user = ref(null)

  async function fetchUser() {
    isLoading.value = true
    try {
      const { data } = await axiosApi.get('/user/')
      user.value = data
    } catch (error) {
      alert.error('An error occurred while fetching the user details.')
      console.log(error)
    } finally {
      isLoading.value = false
    }
  }

  const editablePortalFieldPaths = computed(() => {
    const { portal = [] } = fieldsEditingRules
    return getEditableFieldPaths(user.value, portal)
  })

  const editableClientFieldPaths = computed(() => {
    const { client = [] } = fieldsEditingRules
    return getEditableFieldPaths(user.value, client)
  })

  const canCreatePortal = computed(() => {
    const { create_portal_rights = [] } = actionRights
    return hasSomeRight(user.value, create_portal_rights)
  })

  const canSavePortalDraft = computed(() => (editablePortalFieldPaths.value.length > 0))

  const canPublishPortal = computed(() => {
    const { publish_portal_rights = [] } = actionRights
    return hasSomeRight(user.value, publish_portal_rights)
  })

  const canSaveClient = computed(() => (editableClientFieldPaths.value.length > 0))

  const canEditDevelopersSection = computed(() => {
    const { edit_developers_section_rights = [] } = actionRights
    return hasSomeRight(user.value, edit_developers_section_rights)
  })

  function getEditableFieldPaths(user, fieldsEditingRules = []) {
    // finds the rules based on the user rights
    const matchingRules = fieldsEditingRules.filter(({ rights = [] }) => {
      return hasSomeRight(user, rights)
    })

    return uniq(matchingRules.map(({ paths }) => (paths)).flat())
  }

  function hasSomeRight(user, rights = []) {
    const userRights = getUserRights(user)
    return userRights.some(userRight => rights.includes(userRight))
  }

  function getUserRights(user) {
    return user?.rights || []
  }

  const isCustomerUser = computed(() => {
    // remove this in RX-6160
    return hasSomeRight(user.value, [CUSTOMER_USER_RIGHT])
  })

  return {
    isLoading,
    user,
    isCustomerUser,
    editablePortalFieldPaths,
    editableClientFieldPaths,
    canCreatePortal,
    canSavePortalDraft,
    canPublishPortal,
    canSaveClient,
    canEditDevelopersSection,
    fetchUser
  }
})
